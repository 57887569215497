






































































































































































































































import { Component } from "vue-property-decorator";
import BaseComponent from "../components/BaseComponent";
import ReportHelper from "../components/ReportHelper";
import { ReportType } from "../components/ReportHelper";
import ReportComponent from "../components/ReportComponent.vue";

@Component({
  components: {
    ReportComponent,
  },
})
export default class Reports extends BaseComponent {
  protected baseRoute: string = "/reports";

  private orgName: string = "";
  private ReportType = ReportType;
  private reportHelper: ReportHelper = new ReportHelper();

  protected mounted() {
    this.mount();
  }

  protected changeCurrentOrg() {
    this.mount();
  }

  private mount() {
    this.orgName = this.getCurrentOrgName(this.currentOrg);
  }

  private allReportsHidden(reportTypes: ReportType[]): boolean {
    for (const reportType of reportTypes) {
      const reportName = this.reportHelper.reportTypeEnumToString(reportType);
      if (this.isReportVisible(reportName)) {
        return false;
      }
    }
    return true;
  }
}
