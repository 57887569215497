
























import { Component, Prop } from "vue-property-decorator";
import BaseComponent from "./BaseComponent";
import { ReportType } from "./ReportHelper";

@Component({
  components: {},
})
export default class ReportComponent extends BaseComponent {
  @Prop() private reportType: any;
  @Prop() private reportHelper: any;
  @Prop() private minHeight: any;
  @Prop() private logo: any;

  get reportName(): string {
    return this.reportHelper.reportTypeEnumToString(this.reportType);
  }

  private navReport(reportType: ReportType) {
    const path = "/reports/" + this.reportHelper.reportTypeEnumToString(reportType);
    this.$router.push({ path });
  }

  private isReportDisabled(): boolean {
    return this.isFeatureEnabled(`${this.reportName}-disabled`);
  }

  private getStyle(): string {
    return this.minHeight ? "min-height:" + (this.minHeight as string) + "px" : "";
  }
}
